import BaseRepository from "@/services/resources/repository";
import Validator from "@/services/resources/validator";
import dayjs from "dayjs";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema, Validator.make(model));
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().include('vacancies').sort('createdAt', 'desc').get()
            )
        );
    }

    getForExport(id) {
        const record = this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('vacancy-application-languages')
                    .include('vacancy-application-education')
                    .include('vacancy-application-employment')
                    .include('vacancy-application-references')
                    .find(id)
            )
        ).then(record => {
            if (!record) {
                return record;
            }

            return this.buildExportCsv([
                this.sortVacancyApplicationRelations(record)
            ]);
        });
    }

    getForExportByVacancy(vacancyId) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('vacancyId', vacancyId)
                    .include('vacancy-application-languages')
                    .include('vacancy-application-education')
                    .include('vacancy-application-employment')
                    .include('vacancy-application-references')
                    .sort('createdAt', 'desc')
                    .get()
            )
        ).then(collection => {
            return this.buildExportCsv(
                collection.map(record => {
                    return this.sortVacancyApplicationRelations(record);
                })
            );
        });
    }

    sortVacancyApplicationRelations(record) {
        [
            'vacancyApplicationLanguages',
            'vacancyApplicationEducation',
            'vacancyApplicationEmployment',
            'vacancyApplicationEducation'
        ].forEach(sortableKey => {
            record[sortableKey].sort((a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                }

                if (a.sort > b.sort) {
                    return 1;
                }

                return 0;
            });
        });

        return record;
    }

    buildExportCsv(collection) {
        const headers = [
            'First name',
            'Family name',
            'Place of birth',
            'Nationality 1',
            'Nationality 2',
            'Nationality 3',
            'Gender identity',
            'Specified gender (if any)',
            'Date of birth',
            'Address Line',
            'City',
            'State',
            'Country',
            'Telephone number',
            'Email address',
            'Mother tongue',
            'Other languages',
            'Education - Institution',
            'Education - from',
            'Education - to',
            'Education - degrees and academic distinctions',
            'Education - degree Level',
            'Education - main course of study',
            'Other education',
            'Activities',
            'Publications',
            'Employment 1 - current',
            'Employment 1 - from',
            'Employment 1 - to',
            'Employment 1 - exact title of post',
            'Employment 1 - name of employer',
            'Employment 1 - responsibilities and duties',
            'Employment 2 - current',
            'Employment 2 - from',
            'Employment 2 - to',
            'Employment 2 - exact title of post',
            'Employment 2 - name of employer',
            'Employment 2 - responsibilities and duties',
            'Employment 3 - current',
            'Employment 3 - from',
            'Employment 3 - to',
            'Employment 3 - exact title of post',
            'Employment 3 - name of employer',
            'Employment 3 - responsibilities and duties',
            'Employment 4 - current',
            'Employment 4 - from',
            'Employment 4 - to',
            'Employment 4 - exact title of post',
            'Employment 4 - name of employer',
            'Employment 4 - responsibilities and duties',
            'Employment 5 - current',
            'Employment 5 - from',
            'Employment 5 - to',
            'Employment 5 - exact title of post',
            'Employment 5 - name of employer',
            'Employment 5 - responsibilities and duties',
            'Employment 6 - current',
            'Employment 6 - from',
            'Employment 6 - to',
            'Employment 6 - exact title of post',
            'Employment 6 - name of employer',
            'Employment 6 - responsibilities and duties',
            'Employment 7 - current',
            'Employment 7 - from',
            'Employment 7 - to',
            'Employment 7 - exact title of post',
            'Employment 7 - name of employer',
            'Employment 7 - responsibilities and duties',
            'Employment 8 - current',
            'Employment 8 - from',
            'Employment 8 - to',
            'Employment 8 - exact title of post',
            'Employment 8 - name of employer',
            'Employment 8 - responsibilities and duties',
            'Employment 9 - current',
            'Employment 9 - from',
            'Employment 9 - to',
            'Employment 9 - exact title of post',
            'Employment 9 - name of employer',
            'Employment 9 - responsibilities and duties',
            'Employment 10 - current',
            'Employment 10 - from',
            'Employment 10 - to',
            'Employment 10 - exact title of post',
            'Employment 10 - name of employer',
            'Employment 10 - responsibilities and duties',
            'Other employment',
            'Notice period',
            'Cover letter',
            'Reference 1 name',
            'Reference 1 job title',
            'Reference 1 organisation',
            'Reference 1 email address',
            'Reference 2 name',
            'Reference 2 job title',
            'Reference 2 organisation',
            'Reference 2 email address',
            'Reference 3 name',
            'Reference 3 job title',
            'Reference 3 organisation',
            'Reference 3 email address',
        ];

        const rows = collection.map(record => {
            const row = [
                record.firstName || '',
                record.lastName || '',
                record.placeOfBirth || '',
                record.nationality1 || '',
                record.nationality2 || '',
                record.nationality3 || '',
                record.genderIdentity || '',
                record.otherGender || '',
                record.dateOfBirth || '',
                record.address1 || '',
                record.address2 || '',
                record.address3 || '',
                record.address4 || '',
                record.telephoneNumber || '',
                record.emailAddress || '',
                record.motherTongue || '',
            ];

            if (record.vacancyApplicationLanguages.length) {
                row.push('Y');
            } else {
                row.push('N');
            }

            if (record.vacancyApplicationEducation.length) {
                const primaryEducationRow = record.vacancyApplicationEducation[0];

                row.push(primaryEducationRow.institution || '');
                row.push(primaryEducationRow.fromDate || '');
                row.push(primaryEducationRow.toDate || '');
                row.push(primaryEducationRow.qualifications || '');
                row.push(primaryEducationRow.qualificationLevel || '');
                row.push(primaryEducationRow.course || '');

                if (record.vacancyApplicationEducation.length > 1) {
                    row.push('Y')
                } else {
                    row.push('N')
                }
            } else {
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('N');
            }

            row.push(record.activities ? 'Y' : 'N');
            row.push(record.publications ? 'Y' : 'N');

            if (record.vacancyApplicationEmployment.length) {
                let c = 0;
                record.vacancyApplicationEmployment.forEach(primaryEmploymentRow => {
                    if (c < 10) {
                        row.push(primaryEmploymentRow.isCurrent ? 'Y' : 'N');
                        row.push(primaryEmploymentRow.fromDate || '');
                        row.push(primaryEmploymentRow.toDate || '');
                        row.push(primaryEmploymentRow.position || '');
                        row.push(primaryEmploymentRow.employer || '');
                        row.push(primaryEmploymentRow.jobDescription || '');

                    }
                    c++;
                })

                const n = 10 - record.vacancyApplicationEmployment.length;

                if(n > 0){
                    for(let i = 0; i <= n; i++){
                        row.push('');
                        row.push('');
                        row.push('');
                        row.push('');
                        row.push('');
                        row.push('');
                    }
                }

                if (record.vacancyApplicationEmployment.length > 10) {
                    row.push('Y')
                } else {
                    row.push('N')
                }
            } else {
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('N');
            }

            row.push(record.noticePeriod || '');
            row.push(record.coverLetter ? 'Y' : 'N');

            record.vacancyApplicationReferences.forEach(reference => {
                row.push(reference.name || '');
                row.push(reference.jobTitle || '');
                row.push(reference.organisation || '');
                row.push(reference.emailAddress || '');
            });

            return row;
        });

        rows.unshift(headers);

        return rows;
    }
}

export default Repository;
